import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getMasterPMSStatus } from '../../../services/appointment';
import { updateClinicProfile } from '../../../services/clinicProfile';
import Select from 'react-select';
import { refreshPMSStatus } from '../../../services/pms';
export const PMSStatusMap = ({
    clinicId, fetchProfile, clinicUniqueId, pmsStatusMap
}: any) => {
    let [masterPMSStatusList, setMasterPMSStatusList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    let [xcareStatuesMaster, setXcareStatuesMaster] = useState([
        {
            label: "Completed",
            value: "completed"
        },
        {
            label: "Cancelled",
            value: "cancelled"
        },
        {
            label: "Confirmed",
            value: "confirmed"
        },
        {
            label: "Upcoming",
            value: "upcoming",
        },
        {
            label: "Waitlist",
            value: "waitlist"
        },
        {
            label: "Floating",
            value: "floating"
        }
    ]);
    const [mappedStatues, setMappedStatues]: any = useState([]);
    const [originalMap, setOriginalMap]: any = useState([]);
    const { register, handleSubmit, errors, control, watch, setValue } = useForm();
    useEffect(() => {
        getMasterPMSStatusListFunc(clinicId);
    }, []);
    useEffect(() => {
        mapStatusToState();
    }, [pmsStatusMap, masterPMSStatusList]);
    const mapStatusToState = () => {
        if (pmsStatusMap && pmsStatusMap.length > 0 && masterPMSStatusList && masterPMSStatusList.length > 0) {
            const mapped = pmsStatusMap.map((item: any) => {
                let xcareVal;
                let pmsVal;
                if (item.xcare === 'completed') {
                    xcareVal = {
                        label: "Completed",
                        value: "completed"
                    }
                } else if (item.xcare === 'cancelled') {
                    xcareVal = {
                        label: "Cancelled",
                        value: "cancelled"
                    }
                } else if (item.xcare === 'confirmed') {
                    xcareVal = {
                        label: "Confirmed",
                        value: "confirmed"
                    }
                } else if (item.xcare === 'upcoming') {
                    xcareVal = {
                        label: "Upcoming",
                        value: "upcoming",
                    }
                } else if (item.xcare === 'waitlist') {
                    xcareVal = {
                        label: "Waitlist",
                        value: "waitlist"
                    }
                } else if (item.xcare === 'floating') {
                    xcareVal = {
                        label: "Floating",
                        value: "floating"
                    }
                }
                let splitPMS = item?.pms?.split(',');
                if (splitPMS) {
                    pmsVal = splitPMS.map((sItem: any) => {
                        const pmsItem: any = masterPMSStatusList.find((pItem: any) => pItem.status === sItem);
                        return {
                            value: pmsItem.id,
                            label: pmsItem.status
                        }
                    });
                }
                /* if (item.xcare === 'upcoming' || item.xcare === 'waitlist') {
                    let splitPMS = item?.pms?.split(',');
                    if (splitPMS) {
                        pmsVal = splitPMS.map((sItem: any) => {
                            const pmsItem: any = masterPMSStatusList.find((pItem: any) => pItem.status === sItem);
                            return {
                                value: pmsItem.id,
                                label: pmsItem.status
                            }
                        });
                    }
                } else {
                    if (item.pms) {
                        const pmsItem: any = masterPMSStatusList.find((pItem: any) => pItem.status === item.pms);
                        pmsVal = {
                            value: pmsItem.id,
                            label: pmsItem.status
                        }
                    }
                } */
                return {
                    xcare: xcareVal,
                    pms: pmsVal
                }
            });
            setMappedStatues(mapped);
            setOriginalMap(mapped);
        }
    }
    const getMasterPMSStatusListFunc = async (id: string) => {
        try {
            const response = await getMasterPMSStatus(id);
            if (response && response.status && response.status.error === false) {
                setMasterPMSStatusList(response.pmsStatuses);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const onSave = () => {
        const reqData = mappedStatues.map((item: any) => {
            let xcareVal = item?.xcare?.value || '';
            let pmsVal = '';
            if (Array.isArray(item?.pms)) {
                pmsVal = item.pms.map((pItem: any) => pItem.label).toString();
            }
            /* if (item?.xcare?.value === 'upcoming' || item?.xcare?.value === 'waitlist') {
                if (Array.isArray(item?.pms)) {
                    pmsVal = item.pms.map((pItem: any) => pItem.label).toString();
                }
            } else {
                if (item?.pms) {
                    pmsVal = item.pms.label;
                }
            } */
            return {
                xcare: xcareVal,
                pms: pmsVal
            }
        });
        setIsLoading(true);
        updateClinicProfile('pmsStatuses', clinicUniqueId, reqData).then((success) => {
            if (success && success.status && success.status.error === false) {
                setIsLoading(false);
                setIsEdit(false);
                fetchProfile('pmsStatuses');
            }
        }).catch((err) => {
            toast.error(err.msg);
        })
    }
    const onCancel = () => {
        setIsEdit(false);
        setMappedStatues(originalMap);
    }
    const onAdd = (data: any) => {
        setMappedStatues([
            ...mappedStatues,
            {
                xcare: data.xcareStatus,
                pms: data.pmsStatus,
            }
        ]);
        setValue('xcareStatus', '');
        setValue('pmsStatus', '');
    }
    const handleRemoveClick = (item: any) => {
        setMappedStatues(mappedStatues.filter((mItem: any) => {
            return (mItem.xcare !== item.xcare && item.pms !== mItem.pms)
        }));
    }
    const watchXcareStatus = watch('xcareStatus');
    useEffect(() => {
        setValue('pmsStatus', '');
    }, [watchXcareStatus]);
    const handleRefreshPMSStatus = async () => {
        try {
            setIsRefreshing(true);
            const response = await refreshPMSStatus(clinicUniqueId);
            setIsRefreshing(false);
            toast.success("Status refresh successfull.");
            fetchProfile('pmsStatuses');
        } catch (err) {
            setIsRefreshing(false);
            toast.error("Status refresh failed.");
        }
    }
    return (
        <div className="pms-maping-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Map XCARE status to PMS</div>
                        <div>
                            {
                                isEdit && isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                isLoading && !isEdit &&
                                <i className="bi bi-check-circle-fill text-success"></i>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                        onCancel();
                                    }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="btn p-0 btn-link" onClick={() => {
                                        onSave();
                                    }}><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                isRefreshing &&
                                <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                            }
                            {
                                !isRefreshing &&
                                <button className="btn p-0 me-2" onClick={handleRefreshPMSStatus}>
                                    <i className="bi bi-arrow-clockwise"></i>
                                </button>
                            }
                            {
                                !isEdit && !isLoading && !isEdit &&
                                <button className="btn p-0 btn-link" onClick={() => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="insurance-list d-flex flex-wrap">
                                {
                                    mappedStatues.map((item: any) => {
                                        const labelXcare = item?.xcare?.label;
                                        let labelPms = '';
                                        labelPms = item?.pms?.map((item: any) => {
                                            return item.label;
                                        }).toString();
                                        /*  if (item?.xcare?.value === 'upcoming' || item?.xcare?.value === 'waitlist') {
                                             labelPms = item?.pms?.map((item: any) => {
                                                 return item.label;
                                             }).toString();
                                         } else {
                                             labelPms = item?.pms?.label;
                                         } */
                                        return (
                                            <div className="badge w-break d-flex align-items-center text-dark border text-wrap text-start me-2 mb-2 p-2 w-100" key={item?.xcare?.value}>
                                                {labelXcare} - {labelPms}
                                                {
                                                    isEdit &&
                                                    <button className="btn py-0 pe-0 ms-auto" onClick={(e) => {
                                                        handleRemoveClick(item);
                                                    }}><i className="bi bi-x"></i></button>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={"col-12 mt-3 " + (!isEdit ? 'd-none' : '')}>
                            <div className="d-flex add-pms">
                                <div className="input-box flex-fill">
                                    <label className="form-label">XCARE status</label>
                                    <Controller
                                        name="xcareStatus"
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue=''
                                        render={(props) => (
                                            <Select
                                                value={props.value}
                                                onChange={(values: any) => props.onChange(values)}
                                                options={xcareStatuesMaster.filter((item) => {
                                                    const isFoundInMapped = mappedStatues.find((mItem: any) => mItem?.xcare?.value === item.value) !== undefined;
                                                    if (isFoundInMapped) return false;
                                                    return true;
                                                }).map((item: any) => {
                                                    return { value: item.value, label: item.label }
                                                })}
                                            />
                                        )
                                        }
                                    ></Controller>
                                    {
                                        (errors && errors.xcareStatus && errors.xcareStatus.type === 'required') &&
                                        <div className="is-invalid">Required</div>
                                    }
                                </div>
                                <div className="pb-2 mx-3 align-self-end"><i className="bi bi-arrow-left-right"></i></div>
                                <div className="input-box flex-fill">
                                    <label className="form-label">PMS status</label>
                                    <Controller
                                        name="pmsStatus"
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue=''
                                        render={(props) => (
                                            <Select
                                                // isMulti={(watchXcareStatus && (watchXcareStatus.value === 'upcoming' || watchXcareStatus.value === 'waitlist'))}
                                                isMulti={true}
                                                value={props.value}
                                                onChange={(values: any) => props.onChange(values)}
                                                options={masterPMSStatusList.filter((item: any) => {
                                                    const foundMapped = mappedStatues.find((mItem: any) => {
                                                        if (Array.isArray(mItem?.pms)) {
                                                            const isFound = mItem?.pms.find((pItem: any) => pItem.value === item.id);
                                                            if (isFound) {
                                                                return true;
                                                            }
                                                            return false;
                                                        } else {
                                                            if (item.id === mItem?.pms?.value) {
                                                                return true;
                                                            }
                                                            return false;
                                                        }
                                                    }) !== undefined;
                                                    if (foundMapped) return false;
                                                    return true;
                                                }).map((item: any) => {
                                                    return { value: item.id, label: item.status }
                                                })}
                                            />
                                        )
                                        }
                                    ></Controller>
                                    {
                                        (errors && errors.pmsStatus && errors.pmsStatus.type === 'required') &&
                                        <div className="is-invalid">Required</div>
                                    }
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                                <button className="btn btn-primary fw-normal ms-3 rounded align-self-center" type="submit" onClick={handleSubmit(onAdd)}> Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}