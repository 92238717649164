import React, { useEffect } from "react";
import FooterBar from "../../components/footer/FooterBar";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import UserAccount from "../../components/useraccount/UserAccount";
import { IAppState } from "../../store";
import { shallowEqual, useSelector } from "react-redux";
import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/firestore";
import apiConfigs from "../../configs/apiConfigs";
import { print } from "../../utils/global";
import Payment from "../../components/payment/Payment";
import TermsOfUse from "../../components/termsofuse/TermsofUse";
import PrivacyPolicy from "../../components/privacypolicy/PrivacyPolicy";
import { Employee } from "../../components/employee/Employee";
import PlanGetStarted from "../../components/plangetstarted/PlanGetStarted";
import { ChatDetails } from "../../utils/ChatUtils";
import { Practice } from "../../v2/pages/practice/Practice";
import { SubscriptionRoute } from "../../v2/pages/subscription/Subscription";
import { Sidebar } from "../../v2/components/sidebar/SideBar";
import { MessageCenter } from "../../v2/pages/messageCenter/MessageCenter";
import { Provider as ProviderS } from "../../v2/pages/provider/Provider";
import { MySchedule as MySchedulev2 } from "../../v2/pages/myschedule/MySchedule";
import { Patient } from "../../v2/pages/patientSearch/Patient";
import { Dashboard } from "../../v2/pages/dashboard/DashBoard";
import { DashboardCharts } from "../../v2/pages/dashboardCharts/DashboardCharts";
import { ChatList } from "../../v2/pages/chat/ChatList";
import { ChatPinList } from "../../v2/components/chatpin/ChatPinList";
import { PMSPage } from "../../v2/pages/pms/PMSPage";
// import { VoiceCall } from "../../v2/pages/voicecall/VoiceCall";
import { OffersPage } from "../../v2/pages/offers/OffersPage";
import { TaskManager } from "../../v2/pages/taskmanager/TaskManager";
import {CustomEmailEditor} from '../../v2/pages/emailEditor/EmailEditor'
import NotSupported from '../../v2/assets/images/not-supported.png';

const PageContainer = () => {
  const location = useLocation();
  const mode = new URLSearchParams(location.search).get("mode");
  const userDataResponse = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const userDataState = useSelector(
    (state: IAppState) => state.userData,
    shallowEqual
  );
  const history = useHistory();
  useEffect(() => {
    /* Ask for notification permission */
    if (firebase.messaging.isSupported()) {
      Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          const token = await firebase.messaging().getToken();
          console.log("token", token);
           setTokenToFirestore(
            userDataResponse.clinics,
            userDataResponse.doctors,
            token
          );
        }
      });
      /* When token get refreshed */
      firebase.messaging().onTokenRefresh(() => {
        firebase
          .messaging()
          .getToken()
          .then((refreshedToken: any) => {
            console.log("token", refreshedToken);
            setTokenToFirestore(
              userDataResponse.clinics,
              userDataResponse.doctors,
              refreshedToken
            );
          })
          .catch((error) => {
            print("Unable to retrieve latest token");
          });
      });
      /* Listener for push message */
      onPushMessageReceived();
    }
  }, []);
  useEffect(() => {
    if (
      userDataResponse &&
      userDataResponse.status.error === false &&
      userDataResponse.status.msg !== "" &&
      userDataResponse.clinics[0]
    ) {
      if (
        localStorage.getItem("ChatClinicId") ===
        userDataResponse.clinics[0].uniqueId &&
        localStorage.getItem("Chat") !== ""
      ) {
        // already present data for cliic chat
        ChatDetails(userDataResponse.clinics);
      } else {
        ChatDetails(userDataResponse.clinics);
      }
    }
  }, [userDataResponse]);
  useEffect(() => {
    if (
      location.pathname !== '/message-center/campaigns/campaigns' && location.pathname !== '/message-center/campaigns/templates' 
      && location.pathname !== '/message-center/campaigns/patients' && userDataResponse?.role === "agency") {
        history.replace('/message-center/campaigns/campaigns');
        return;
    }
  }, [location.pathname])
  const setTokenToFirestore = (
    clinicIds: any[],
    doctorIds: any[],
    token: any
  ) => {
    if (clinicIds.length !== 0) {
      var db = firebase.firestore();
      clinicIds.forEach((value) => {
        db.collection(apiConfigs.TOKEN_COLLECTION)
          .where("providerType", "==", "clinic")
          .where("providerId", "==", value.uniqueId)
          .get()
          .then((querySnapShot) => {
            if (querySnapShot.empty) {
              db.collection(apiConfigs.TOKEN_COLLECTION)
                .doc()
                .set({
                  providerType: "clinic",
                  providerId: value.uniqueId,
                  token: [token],
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                });
            } else {
              querySnapShot.forEach((doc) => {
                if (!doc.data().token.includes(token)) {
                  db.collection(apiConfigs.TOKEN_COLLECTION)
                    .doc(doc.id)
                    .update({
                      token: firebase.firestore.FieldValue.arrayUnion(token),
                      updatedAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                    });
                }
              });
            }
          });
      });
    }
  };
  const onPushMessageReceived = () => {
    firebase.messaging().onMessage((payload) => {
      console.log("payload", payload);
      const currentUrl = window.location.href;
      const urlParts = currentUrl.split("/");
      if (urlParts[3] === "chats") {
        return;
      }
      payload = JSON.parse(payload.data.data);
      let notification: any;
      if (payload.sender === "utility" && payload.actionType === "STATUS" &&
        payload.fromStatus === "CREATED" &&
        payload.toStatus === "GUEST") {
        const notificationTitle = "New appointment is requested.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
        return;
      }
      if (
        payload.actionType === "STATUS" &&
        payload.fromStatus === "CREATED" &&
        payload.toStatus === "REQUESTED"
      ) {
        const notificationTitle =
          "New appointment is requested by " +
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname;
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (
        payload.actionType === "MESSAGE" &&
        payload.sender !== "user"
      ) {
        const notificationTitle =
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has sent a new chat message";
        notification = new Notification(notificationTitle, {
          body: payload.text,
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (payload.actionType === "PRESCREENING") {
        const notificationTitle =
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has completed the Pre-screening.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (payload.actionType === "PAYMENT_SUCCESSFUL") {
        const notificationTitle =
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has made the payment.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (payload.actionType === "CHECKIN") {
        const notificationTitle =
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has checked-in.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (payload.actionType === "CONFIRM_BY_PATIENT") {
        const notificationTitle =
          "" +
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has confirmed the appointment.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (payload.actionType === "CANCELLED_BY_PATIENT") {
        const notificationTitle =
          "" +
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has requested an appointment reschedule.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      } else if (payload.actionType === "TRIAGE_EMERGENCY") {
        const notificationTitle =
          "" +
          payload.patient.patient_fname +
          " " +
          payload.patient.patient_lname + " has posted an Emergency Triage request.";
        notification = new Notification(notificationTitle, {
          body: "",
          icon: `${process.env.PUBLIC_URL}/logo/logonot.png`,
          data: payload,
        });
      }
      /* Do not remove below code */
      /* if (notification) {
                notification.onclick = function (event: any) {
                    event.preventDefault();
                    let payload = event.target.data;
                    if (payload.sendToCId && payload.appointmentId) {
                        let url = window.location.protocol + '//' + window.location.host + '/clinic/' + payload.sendToCId + '/appointment/' + payload.appointmentId;
                        window.open(url, '_blank');
                    }
                }
            } */
    });
  };
  return (
    <>
      <div className={"d-flex d-lg-none not-supported-box justify-content-center "+ (mode && mode=='desktop' ? 'no-not-supported': '')}>
        <div className="align-self-center">
          <img
            src={NotSupported}
            className="img-fluid mb-5"
          />
          <p className="text-center px-4">
            Oops !!! View for this resolution is not supported. Please use an
            iPad or Android tablet in landscape mode or Desktop to access Clinic
            ADMIN panel features.
          </p>
        </div>
      </div>
      <div className={mode && mode=='desktop' ? "" : "d-none d-lg-block"}>
        <React.Fragment>
          {
            mode !== "desktop" &&
            <Sidebar></Sidebar>
          }
          <div
            className={
              mode === "desktop" ? "" :
              location.pathname === "/myschedule"
                ? "main-middle-sec shrink"
                : "main-middle-sec shrink page-container "
            }
          >
            <div
              className={
                "main-content " +
                (location.pathname === "/myschedule"
                  ? "myschedule-page"
                  : location.pathname === "/user/appointments"
                    ? "pmsappointment-page"
                    : location.pathname === "/chats"
                      ? "chat-page"
                      : "")
              }
            >
              {/* Routes */}
              <div className="position-relative" id="position-rel">
                <Switch>
                  <Route exact path="/editor" component={CustomEmailEditor} />
                  <Route path="/task-manager" component={TaskManager} />
                  <Route path="/offers" component={OffersPage} />
                  <Route path="/practice" component={Practice} />
                  <Route path="/subscription" component={SubscriptionRoute} />
                  <Route path="/provider" component={ProviderS}></Route>
                  <Route
                    path="/message-center"
                    component={MessageCenter}
                  ></Route>
                  <Route path="/dashboard" component={DashboardCharts}></Route>
                  <Route path="/myschedule" component={MySchedulev2}></Route>
                  <Route path="/chats" component={ChatList}></Route>
                  <Route path="/patient" component={Patient}></Route>
                  <Route path="/pms" component={PMSPage}></Route>
                  <Route
                    exact
                    path="/user/employee"
                    component={Employee}
                  ></Route>
                  <Route
                    exact
                    path="/user/payment/:type"
                    component={Payment}
                  ></Route>
                  <Route
                    exact
                    path="/terms-condition"
                    component={TermsOfUse}
                  ></Route>
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  ></Route>
                  <Route
                    exact
                    path="/user/plan/get-started"
                    component={PlanGetStarted}
                  />
                  <Route
                    exact
                    path="/user/:type/:subtype?"
                    component={UserAccount}
                  ></Route>
                  <Redirect to="/myschedule"></Redirect>
                </Switch>
                {userDataResponse && userDataResponse.clinics.length > 0 && (
                  <div className="fab-bottom">
                    <ChatPinList></ChatPinList>
                  </div>
                )}
              </div>
            </div>
            {location.pathname !== "/chats" && <FooterBar></FooterBar>}
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
const requestPermissionNotification = () => { };
export default PageContainer;
